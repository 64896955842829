import Repo from './_repo.js'
import { NotInitializedError } from '../exceptions.js'

let indexingPromise = null
const testCards = []
let cardCache = null
let blockingTimer = null

export default class extends Repo {
  static create(data) {
    return $tpu.r.data.restFetch(`/cards`, 'POST', data).then((card) => {
      if (card) { // Success
        this._delayCacheUpdate()

        // Need to wait for the card to be available to the PowerUps before we can attach data to it.
        // _recursiveWaitForNewCard() will indirectly call _delayCacheUpdate()
        return this._waitForNewCard(card.id).then((card) => {
          // The following statements should execute after _waitForNewCard() because they need `card.dateLastActivity`.
          this._initShapeUp(card, null)
          $tpu.r.checklists.registerCard(card)
          cardCache[card.id] = card
          return card
        })
      }
      return null
    })
  }

  static update(cardId, updatedData) {
    this._lockCacheUpdate(3000)

    // Record the timestamp before updating so that the local cache will eventually get replaced with the actual
    // remote data.
    const currentTimestamp = new Date().getTime()

    return $tpu.r.data.restFetch(`/cards/${cardId}`, 'PUT', updatedData).then((result) => {
      if (result) { // Success

        this._delayCacheUpdate() // Update/extend the lock just to be really sure.
        const card = cardCache[cardId]
        Object.assign(card, updatedData)

        // This is for immediate UI refresh, but the side effect is that another UI refresh will happen once
        // the remote data is fetched.
        card.shapeUp.updatedAt = currentTimestamp
      }
      this._unlockCacheUpdate()
      return result
    })
  }

  // This takes care of the deletion of raw cards. Scopes and Tasks will be unlinked later in
  // `r.scopes` and `r.tasks`.
  static destroy(cardId) {
    return $tpu.r.data.restFetch(`/cards/${cardId}`, 'DELETE').then((result) => {
      if (result) { // Success
        this._delayCacheUpdate()

        // This is the safest, simplest, and potentially most performant way to handle all scenarios. Without this,
        // reindexing still happens on the source machine. This also reduces the frequency of a scenario where
        // new indices arrive on a peer machine ahead of the card deletion.
        return this._waitForDeletedCard(cardId).then(() => {
          delete cardCache[cardId]
        })
      }
      return result
    })
  }

  static _recursiveWaitForNewCard(resolve, reject, cardId, t, retryCount) {
    if (retryCount > 100) { // Give up after 5 seconds
      reject(`Card not found: ${cardId}`)
    }
    setTimeout(() => {
      this._registry(t, true).then((registry) => {
        const card = registry[cardId]
        if (card) {
          console.debug(`Found card in ${retryCount} retries`)
          resolve(card)
        } else {
          this._recursiveWaitForNewCard(resolve, reject, cardId, t, retryCount + 1)
        }
      })
    }, 50)
  }

  static setData(cardId, params, t, disableTouch) {
    const timestamp = new Date().getTime()
    const card = cardCache[cardId]

    let newData
    if (this.isInTrello()) {
      if (disableTouch) {
        newData = params
      } else {
        newData = Object.assign(params, { updatedAt: timestamp })
      }
    } else {
      // Don't interfere with the test data's updatedAt values.
      newData = params
    }

    // Update the local cache for immediate availability.
    Object.assign(card.shapeUp, newData)

    // Only save the new data (not the whole shapeUp property) to prevent data loss due to concurrent updates.
    // See https://developer.atlassian.com/cloud/trello/power-ups/client-library/getting-and-setting-data/
    return $tpu.r.data.set(t, cardId, "shared", newData)
  }

  static _waitForNewCard(cardId) {
    return new Promise((resolve, reject) => {
      const t = TrelloPowerUp.iframe()
      this._recursiveWaitForNewCard(resolve, reject, cardId, t, 0)
    })
  }

  static _waitForDeletedCard(cardId) {
    return new Promise((resolve, reject) => {
      const t = TrelloPowerUp.iframe()
      this._recursiveWaitForDeletedCard(resolve, reject, cardId, t, 0)
    })
  }

  static _recursiveWaitForDeletedCard(resolve, reject, cardId, t, retryCount) {
    if (retryCount > 100) { // Give up after 5 seconds
      reject(`Card not deleted: ${cardId}`)
    }
    setTimeout(() => {
      this._registry(t, true).then((registry) => {
        const card = registry[cardId]
        if (card) {
          this._recursiveWaitForDeletedCard(resolve, reject, cardId, t, retryCount + 1)
        } else {
          console.debug(`Card deletion confirmed in ${retryCount} retries`)
          resolve()
        }
      })
    }, 50)
  }

  static async fetchData(cardId, t) {
    try {
      return await t.get(cardId, 'shared')
    } catch (e) {
      console.warn("Card data not found", cardId)
      // Power-Up data of the card is not found. This is common when a card is in the process of getting deleted.
      return null
    }
  }

  static _initShapeUp(card, data) {
    card.shapeUp = data || { updatedAt: 0 }

    if (!GLib.type.isNumber(card.shapeUp.riskLevel)) {
      card.shapeUp.riskLevel = 0
    }

    const lastActivity = Date.parse(card.dateLastActivity)
    if (lastActivity > card.shapeUp.updatedAt) {
      card.shapeUp.updatedAt = lastActivity
    }
  }

  static async _all(t) {
    if (this.isInTrello()) {
      const cards = await t.cards('id', 'name', 'desc', 'url', 'idList', 'members', 'dateLastActivity')
      const promises = cards.map((card) => {
        return this.fetchData(card.id, t).then((data) => {
          if (data) {
            this._initShapeUp(card, data)
            return card
          }
          return null
        })
      })
      const array = await Promise.all(promises)
      return array.filter(n => n) // Remove nulls
    }

    const registry = await $tpu.r.projects.registry(t)
    if (testCards.length <= 0) {
      testCards.push(...this._primaryTestCards(registry))

      setTimeout(() => {
        testCards.clear();
        testCards.push(...this._primaryTestCards(registry))
        testCards.push(...this._secondaryTestCards(registry))
      }, 6000) // Simulate incoming cards after 6 seconds
    }
    return testCards
  }

  static _delayCacheUpdate() {
    this._lockCacheUpdate(1000) // Lock for a standard duration
  }

  // This method ensures cache doesn't get immediately reset back to the previous state thus overriding the
  // recent changed made to the local cache. The delay allows recently modified remote data to be available.
  // For this to be effective, make sure to call the method early on (e.g. before calling restFetch()) in
  // order to allow sufficient time for the completion of currently executing _registryPromise().
  //
  // It also ensures sequential updating which is beneficial as explained in  `scopes#_reindex`
  static _lockCacheUpdate(duration) {
    // Ensures there is only one setTimeout() which makes things run sequentially.
    clearTimeout(blockingTimer)
    // Let the promise be reused by other requests that were made within X seconds.
    blockingTimer = setTimeout(() => {
      indexingPromise = null
    }, duration)
  }

  static _unlockCacheUpdate() {
    // Lock the cache for another short duration to allow time for remote data to come through.
    this._delayCacheUpdate()
  }

  static async _registryPromise(t) {
    const cards = await this._all(t)

    const localCache = {}
    cards.forEach((card) => {
      localCache[card.id] = card
    })

    cardCache = localCache

    this._delayCacheUpdate()

    return localCache
  }

  static _registry(t, forceFetch) {
    if (indexingPromise && !forceFetch) {
      // console.debug("Reusing card indexing promise...")
      return indexingPromise
    }

    // console.debug("Fetching new indexed cards...", indexingPromise, forceFetch)

    indexingPromise = this._registryPromise(t)
    return indexingPromise
  }

  static registry(t) {
    return this._registry(t, false)
  }

  static cache() {
    const tempCache = cardCache
    // if (Object.keys(tempCache).length <= 0) {
    //   throw new NotInitializedError()
    // }

    if (tempCache === null) {
      throw new NotInitializedError()
    }

    return tempCache
  }

  static findAll() {
    return Object.values(cardCache)
  }

  static find(cardId) {
    return cardCache[cardId]
  }

  static currentId(t) {
    return t.card('id').then((card) => {
      return card.id
    })
  }

  static $mergeMembers(members, cardId, currentTimestamp) {
    const card = cardCache[cardId]
    card.members = members
    card.shapeUp.updatedAt = currentTimestamp
  }

  static _primaryTestCards(registry) {
    const projectIds = Object.keys(registry)

    return [
      { name: 'Scope One', id: 'story1', idList: 'list1', members: [], shapeUp: {
        taskCardIds: ['task1', 'task2', 'task9'],
        projectId: projectIds[0],
        updatedAt: 1681665045651
      } },
      { name: 'Scope Two', id: 'story2', idList: 'list1', members: [], shapeUp: {
        taskCardIds: ['task3', 'task4'],
        projectId: projectIds[1],
        updatedAt: 1681665045651
      } },
      { name: 'Scope Three', id: 'story3', idList: 'list1', members: [], shapeUp: {
        projectId: projectIds[1],
        updatedAt: 1681665045651
      } },
      { name: 'Task one', id: 'task1', idList: 'list1', members: [], shapeUp: {
        scopeCardId: 'story1',
        updatedAt: 1682664939373
      } },

      { name: 'Scope Four', id: 'story4', idList: 'list2', members: [], shapeUp: {
        taskCardIds: ['task5', 'task6'],
        projectId: projectIds[0],
        updatedAt: 1681665045651,
        hillPointX: 6
      } },
      { name: 'Task two', id: 'task2', idList: 'list2', members: [
        { avatar: "https://www.signivis.com/img/custom/avatars/gabi_avatar.png" }
      ], shapeUp: {
        scopeCardId: 'story1'
      } },
      { name: 'Task Three', id: 'task3', idList: 'list2', members: [], shapeUp: {
        scopeCardId: 'story2'
      } },
      { name: 'Task Four', id: 'task4', idList: 'list2', members: [], shapeUp: {
        scopeCardId: 'story2'
      } },
      { name: 'Task Five with very very very very very long name', id: 'task5', idList: 'list2', members: [
        { avatar: "https://www.nicepng.com/png/full/186-1866063_dicks-out-for-harambe-sample-avatar.png" },
        { avatar: "https://artcorgi.com/wp-content/uploads/2014/09/bored.png" }
      ], shapeUp: {
        scopeCardId: 'story4'
      } },
      { name: 'Unscoped', id: 'story5', idList: 'list3', members: [], shapeUp: {
        projectId: projectIds[2],
        updatedAt: 1682665045710
      } },
    ]
  }

  static _secondaryTestCards(registry) {
    const projectIds = Object.keys(registry)
    return [
      { name: 'Scope Six', id: 'story6', idList: 'list3', members: [], shapeUp: {
        projectId: projectIds[0],
        updatedAt: 1682665045710
      } },
      { name: 'Scope Seven', id: 'story7', idList: 'list3', members: [], shapeUp: {
        projectId: projectIds[0],
        updatedAt: 1682665045710
      } },
      { name: 'Task Six', id: 'task6', idList: 'list3', members: [], shapeUp: {
        scopeCardId: 'story4',
        updatedAt: 1682665045820
      } },
      { name: 'Task Seven', id: 'task7', idList: 'list3', members: [], shapeUp: {
        scopeCardId: 'story5'
      } },
      { name: 'Task Eight', id: 'task8', idList: 'list3', members: [], shapeUp: {
        scopeCardId: 'story6'
      } },
      { name: 'Task Nine', id: 'task9', idList: 'list3', members: [], shapeUp: {
        scopeCardId: 'story1',
        updatedAt: 1682665045820
      } },
    ]
  }
}
